import React, { useEffect } from "react"

import Layout from "../components/layout"
import Nav from "../components/navigation"
import SEO from "../components/seo"
import VideoContainer from "../components/videocontainer"

import zoomBlack from "../video/zoom-black.mp4"

const IndexPage = () => {
  useEffect(() => {
    document.body.style.backgroundColor = "#111";
  }, [])
  return (
    <Layout className="blackLayout">
      <SEO title="Pintu Logo Animation | Black Zoom Reveal" />
      <Nav />
      <VideoContainer src={zoomBlack} />
    </Layout>
  )
}

export default IndexPage
